export default () => {

  let slides = [...document.querySelectorAll('.news-article__slide')];
  let btns = [...document.querySelectorAll('.news-article__btn')];
  let currentSlide = 1;
  
  if(slides.length > 0){

    let manualNav = (manual) => {
      slides.forEach(slide => {
        slide.classList.remove('active');
      });    
      btns.forEach(btn => {
        btn.classList.remove('active');
      });
      slides[manual].classList.add('active');
      btns[manual].classList.add('active');
    };

    btns.forEach((btn, i) => {
      btn.addEventListener('click', () => {
        manualNav(i);
        currentSlide = i;
      });    
    });
    
  }

};