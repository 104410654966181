import Navigation from './navigation/index';
import Gallery from './gallery';
import NewsGallery from './news_article';
import SwiperInit from './swiper';

(() => {
  document.addEventListener('turbo:load', () => {
    Navigation();
    Gallery();
    NewsGallery();
    SwiperInit();
	})
})();