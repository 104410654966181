import { Controller } from "@hotwired/stimulus"
import Pikaday from 'pikaday';

// Connects to data-controller="pikaday-init"
export default class extends Controller {
  connect() {
    console.log('Pika Loaded')
    this.element.autocomplete = 'off';
    const picker = new Pikaday({
      field: this.element,
      format: 'D/M/YYYY',
      minDate: new Date(2023, 1, 1),
      maxDate: new Date(2100, 1, 1),
    });
  }
}
