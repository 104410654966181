import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alerts"
export default class extends Controller {

  static targets = ['top']

  connect() {
    const container = this.element;
    let alertsHigh = document.querySelector(".alert-high");
    let alertsMed = document.querySelector(".alert-medium");
    let alertsLow = document.querySelector(".alert-low");

    if(alertsHigh){
      this.topTarget.classList.add('high');
    } else if(alertsMed){
      this.topTarget.classList.add('med');
    } else if (alertsLow) {
      this.topTarget.classList.add('low');
    } else {
      this.topTarget.style.backgroundColor = 'var(--red)';
      this.topTarget.style.paddingTop = "0px";
    }
  }
  toggle() {
    this.element.classList.toggle('open')
  }
}
