import Swiper, { Navigation, Pagination } from 'swiper';

export default () => {
  const swiperHero = new Swiper('.swiper-hero', {
    modules: [Navigation, Pagination],    
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    slidesPerGroup:1,
    spaceBetween:0,
    pagination: {
      el:'.swiper-pagination-hero',
      clickable: true,
      renderBullet: function () {
        return '<span class="swiper-pagination-bullet">'+"</span>";
      },
    },
    navigation: {
      nextEl: ".swiper-button-next.hero-carousel-btn",
      prevEl: ".swiper-button-prev.hero-carousel-btn",
    }, 
  });

  const swiperImgLeft = new Swiper('.multiple-image-left', {
    modules: [Pagination],    
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    slidesPerGroup:1,
    spaceBetween:10,
    pagination: {
      el:'.multiple-image-left-nav',
      clickable: true,
      renderBullet: function () {
        return '<span class="swiper-pagination-bullet">'+"</span>";
      },
    },
  });

  const swiperImgRight = new Swiper('.multiple-image-right', {
    modules: [Pagination],    
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    slidesPerGroup:1,
    spaceBetween:10,
    pagination: {
      el:'.multiple-image-right-nav',
      clickable: true,
      renderBullet: function () {
        return '<span class="swiper-pagination-bullet">'+"</span>";
      },
    },
  });

  if(window.innerWidth <= 800){
    const swiperThirdWidth = new Swiper('.third-width-swiper', {
      modules: [Navigation, Pagination],    
      direction: 'horizontal',
      loop: false,
      slidesPerView: 1.5,
      slidesPerGroup:1,
      spaceBetween: 32,
      centeredSlides: true,
      // centeredSlidesBounds: true,
      wrapperClass: "third-width-swiper-wrapper",
      pagination: false,
      navigation: {
        nextEl: ".swiper-button-next.third-width-carousel-btn",
        prevEl: ".swiper-button-prev.third-width-carousel-btn",
      }, 
    });
    console.log('swiper THIRD loaded'); 
  }


  console.log('swiper loaded'); 
};
