import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="banner-img"
export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.containerTarget
    const mobileImg = this.containerTarget.getAttribute("data-mobile")
    const tabletImg = this.containerTarget.getAttribute("data-tablet")
    const desktopImg = this.containerTarget.getAttribute("data-desktop")

    if(window.innerWidth > 1024){                                            //desktop
      if(desktopImg !== '/' ){
        this.containerTarget.style.backgroundImage = `url('${desktopImg}')`;
      } else if(tabletImg == '/') {
        this.containerTarget.style.backgroundImage = `url('${tabletImg}')`;
      } else {
        this.containerTarget.style.backgroundImage = `url('${mobileImg}')`;
      }
    } else if (window.innerWidth < 1025 && window.innerWidth > 501){         //tablet
      if(tabletImg !== '/'){
        this.containerTarget.style.backgroundImage = `url('${tabletImg}')`;
      } else if(mobileImg !== '/') {
        this.containerTarget.style.backgroundImage = `url('${mobileImg}')`;
      } else {
        this.containerTarget.style.backgroundImage = `url('${desktopImg}')`;
      }
    } else {                                                                //mobile
      if(mobileImg !== '/'){
        this.containerTarget.style.backgroundImage = `url('${mobileImg}')`;
      } else if(tabletImg !== '/') {
        this.containerTarget.style.backgroundImage = `url('${tabletImg}')`;
      } else {
        this.containerTarget.style.backgroundImage = `url('${desktopImg}')`;
      }
    }
      
  }
}