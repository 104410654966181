import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  copy() {
    console.log("copying to clipboard")
    event.preventDefault()

    const text = this.element.href 

    navigator.clipboard.writeText(text)
    this.element.innerHTML = "Copied!"
    setTimeout(() => {  
      this.element.innerHTML = "Copy"
    }, 1000);
  }
}
