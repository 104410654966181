import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="expandable"
export default class extends Controller {
  static targets = ['body', 'btn']

  open() {
    this.bodyTarget.classList.toggle('open');
    let svg = this.btnTarget.querySelector('svg');
    svg.classList.toggle('spin');    
  }
}
