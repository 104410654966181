export default () => {

  let hamburger = document.querySelector('.nav-hamburger');
  let navigationDraw = document.querySelector('.navigation-draw');
  let subnav = [...document.querySelectorAll('.header-nav-sub-list')];

  if(navigationDraw){
    hamburger.addEventListener('click', () => {
      hamburger.classList.toggle('open');
      navigationDraw.classList.toggle('open');
    });
  }

  if(subnav && window.innerWidth < 1200){



    subnav.forEach(e => {
      let btn = e.closest('.header-nav-list__item');
      btn.addEventListener("click",()=>{
        e.classList.toggle('open');
        btn.classList.toggle('open');
        console.log(e)
      });
    });

  }

}